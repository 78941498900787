var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col min-h-full"},[_c('div',{staticClass:"flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6"},[_c('h3',{staticClass:"text-gray-500 text-lg"},[_vm._v("Novo link de pagamento")]),_c('Button',{staticClass:"-mr-3",attrs:{"kind":"plain","aria-label":"Close"},on:{"click":function($event){return _vm.showModal()}}},[_c('XIcon',{attrs:{"size":"24"}}),_c('span',{staticClass:"sr-only"},[_vm._v(" Fechar ")])],1)],1),_c('div',{staticClass:"flex-grow bg-white lg:bg-gray-100 w-full"},[_c('div',{staticClass:"max-w-lg mx-auto bg-white lg:border lg:rounded-xl lg:my-6"},[_c('header',[_c('h1',{staticClass:"flex flex-col flex-grow font-bold text-xl leading-9 gap-3",class:{
      'p-6': !_vm.isExpired,
      'p-6 pb-0': _vm.isExpired && !_vm.isEditing,
      'p-6 pb-6': _vm.isExpired && _vm.isEditing,
    }},[_vm._v(" "+_vm._s(_vm.isEditing || _vm.isEdited ? "Pré-análise de crédito" : _vm.isExpired ? "Crédito expirado" : "Crédito pré-aprovado")+" ")]),(_vm.isEditing)?_c('div',{staticClass:"flex px-6 py-3 bg-yellow-100"},[_c('span',{staticClass:"w-full flex flex-col sub-message text-yellow-700"},[_vm._v(" Ao modificar os dados do cliente pré-aprovado, será realizada uma nova análise de crédito. ")])]):(!_vm.isExpired && !_vm.isEdited)?_c('div',{staticClass:"grid lg:grid-cols-2 lg:items-center gap-2 px-6 py-3 bg-green-50 text-green-700 text-sm"},[_c('div',{staticClass:"flex lg:flex-col gap-3 lg:gap-0"},[_c('div',{staticClass:"lg:flex w-full"},[_c('div',{staticClass:"mr-1"},[_vm._v("Cliente:")]),_c('div',{staticClass:"font-semibold capitalize"},[_vm._v(" "+_vm._s(_vm.clientName)+" ")])]),_c('div',{staticClass:"lg:flex w-full"},[_c('div',{staticClass:"mr-1"},[_vm._v("Limite com entrada:")]),_c('div',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.maxValueWithDownPayment))])])]),(_vm.maxValueWithoutDownPayment)?_c('div',{staticClass:"w-full flex flex-col text-green-700 border border-green-600 rounded-lg bg-white px-3 py-2"},[_c('div',[_vm._v("Limite sem entrada")]),_c('div',{staticClass:"font-semibold text-base"},[_vm._v(" "+_vm._s(`até ${_vm.maxValueWithoutDownPayment.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`)+" ")])]):_vm._e()]):_vm._e()]),_c('form',{staticClass:"flex flex-col gap-5 px-6",class:{
      'py-6': !_vm.isExpired,
      'pb-6 pt-5': _vm.isExpired,
      'pb-6 pt-0': _vm.isEdited,
    }},[_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"form-label"},[_vm._v("CPF")]),_c('span',{staticClass:"px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400",class:{
      'bg-red-50 border-red-500': !_vm.formInputs.cpf.isValid
    }},[_vm._v(" "+_vm._s(_vm.hideCPF(_vm.paymentLinkUser.cpf))+" ")]),_c('span',{staticClass:"text-sm text-red-700"},[_vm._v(_vm._s(_vm.getMessage('cpf')))])]),_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"form-label"},[_vm._v("Celular")]),_c('Input',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditing),expression:"isEditing"},{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"type":"text","required":"","errorMessage":_vm.getMessage('phone')},on:{"input":function($event){return _vm.handleFormInput('phone', ...arguments)}},model:{value:(_vm.paymentLinkUser.phone),callback:function ($$v) {_vm.$set(_vm.paymentLinkUser, "phone", $$v)},expression:"paymentLinkUser.phone"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400",class:{
      'bg-red-50 border-red-500': !_vm.formInputs.phone.isValid
    }},[_vm._v(" "+_vm._s(_vm.hidePhone(_vm.paymentLinkUser.phone))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"text-sm text-red-700"},[_vm._v(_vm._s(_vm.getMessage('phone')))])],1),_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"form-label"},[_vm._v("Email")]),_c('Input',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditing),expression:"isEditing"}],attrs:{"type":"text","required":"","errorMessage":_vm.getMessage('email')},on:{"input":function($event){return _vm.handleFormInput('email', ...arguments)}},model:{value:(_vm.paymentLinkUser.email),callback:function ($$v) {_vm.$set(_vm.paymentLinkUser, "email", $$v)},expression:"paymentLinkUser.email"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400",class:{
      'bg-red-50 border-red-500': !_vm.formInputs.email.isValid
    }},[_vm._v(" "+_vm._s(_vm.hideEmail(_vm.paymentLinkUser.email))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"text-sm text-red-700"},[_vm._v(_vm._s(_vm.getMessage('email')))])],1),_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"form-label"},[_vm._v("CEP")]),_c('Input',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditing),expression:"isEditing"},{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"type":"text","required":"","errorMessage":_vm.getMessage('zipCode')},on:{"input":function($event){return _vm.handleFormInput('zipCode', ...arguments)}},model:{value:(_vm.paymentLinkUser.zipCode),callback:function ($$v) {_vm.$set(_vm.paymentLinkUser, "zipCode", $$v)},expression:"paymentLinkUser.zipCode"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400",class:{
      'bg-red-50 border-red-500': !_vm.formInputs.zipCode.isValid
    }},[_vm._v(" "+_vm._s(_vm.hideCEP(_vm.paymentLinkUser.zipCode))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditing),expression:"!isEditing"}],staticClass:"text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.getMessage('zipCode'))+" ")])],1),(!_vm.isEditing && !_vm.isEdited)?_c('Button',{staticClass:"max-w-max",on:{"click":function($event){return _vm.editUserData()}}},[_c('PencilAltIcon',{staticClass:"h-6 w-6"}),_vm._v(" Editar dados ")],1):_vm._e()],1)])]),_c('nav',{staticClass:"py-4 px-6 border-t bg-white lg:sticky lg:bottom-0 lg:z-10"},[_c('div',{staticClass:"flex gap-4"},[_c('div',{staticClass:"flex flex-grow gap-4 justify-end"},[(_vm.isEditing)?_c('Button',{staticClass:"w-full lg:w-auto",on:{"click":function($event){_vm.editUserData();
    _vm.isEdited = false;}}},[_vm._v(" Cancelar ")]):_vm._e(),_c('Button',{staticClass:"w-full lg:w-auto",attrs:{"link":"","kind":"primary"},on:{"click":function($event){_vm.isEditing ? _vm.updateUser() : _vm.applyPreApprovedCreditForPaymentLink()}}},[_vm._v(" "+_vm._s(_vm.isEditing ? "Salvar informações" : _vm.isExpired || _vm.isEdited ? "Analisar crédito" : "Avançar")+" ")])],1)])]),_c('CancelCreatePaymentLinkModal',{attrs:{"showModal":_vm.openModal},on:{"onCloseModal":_vm.showModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }