<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6">
      <h3 class="text-gray-500 text-lg">Novo link de pagamento</h3>
      <Button kind="plain" aria-label="Close" class="-mr-3" @click="showModal()">
        <XIcon size="24" />
        <span class="sr-only"> Fechar </span>
      </Button>
    </div>
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white lg:border lg:rounded-xl lg:my-6">
        <header>
          <h1 :class="{
        'p-6': !isExpired,
        'p-6 pb-0': isExpired && !isEditing,
        'p-6 pb-6': isExpired && isEditing,
      }" class="flex flex-col flex-grow font-bold text-xl leading-9 gap-3">
            {{
        isEditing || isEdited
          ? "Pré-análise de crédito"
          : isExpired
            ? "Crédito expirado"
            : "Crédito pré-aprovado"
      }}
          </h1>
          <div v-if="isEditing" class="flex px-6 py-3 bg-yellow-100">
            <span class="w-full flex flex-col sub-message text-yellow-700">
              Ao modificar os dados do cliente pré-aprovado, será realizada uma
              nova análise de crédito.
            </span>
          </div>
          <div v-else-if="!isExpired && !isEdited" class="grid lg:grid-cols-2 lg:items-center
            gap-2 px-6 py-3 bg-green-50 text-green-700 text-sm">
            <div class="flex lg:flex-col gap-3 lg:gap-0">
              <div class="lg:flex w-full">
                <div class="mr-1">Cliente:</div>
                <div class="font-semibold capitalize">
                  {{ clientName }}
                </div>
              </div>
              <div class="lg:flex w-full">
                <div class="mr-1">Limite com entrada:</div>
                <div class="font-semibold">{{ maxValueWithDownPayment }}</div>
              </div>
            </div>
            <div class="w-full flex flex-col text-green-700 border
              border-green-600 rounded-lg bg-white px-3 py-2" v-if="maxValueWithoutDownPayment">
              <div>Limite sem entrada</div>
              <div class="font-semibold text-base">
                {{ `até ${maxValueWithoutDownPayment.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}` }}
              </div>
            </div>
          </div>
        </header>
        <form :class="{
        'py-6': !isExpired,
        'pb-6 pt-5': isExpired,
        'pb-6 pt-0': isEdited,
      }" class="flex flex-col gap-5 px-6">
          <div class="flex flex-col">
            <label class="form-label">CPF</label>
            <span class="px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400" :class="{
        'bg-red-50 border-red-500': !formInputs.cpf.isValid
      }">
              {{ hideCPF(paymentLinkUser.cpf) }}
            </span>
            <span class="text-sm text-red-700">{{ getMessage('cpf') }}</span>
          </div>
          <div class="flex flex-col">
            <label class="form-label">Celular</label>
            <Input v-show="isEditing" type="text" v-model="paymentLinkUser.phone"
              @input="handleFormInput('phone', ...arguments)" v-mask="'(##) #####-####'" required
              :errorMessage="getMessage('phone')" />
            <span v-show="!isEditing" class="px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400"
              :class="{
        'bg-red-50 border-red-500': !formInputs.phone.isValid
      }">
              {{ hidePhone(paymentLinkUser.phone) }}
            </span>
            <span v-show="!isEditing" class="text-sm text-red-700">{{ getMessage('phone') }}</span>
          </div>
          <div class="flex flex-col">
            <label class="form-label">Email</label>
            <Input v-show="isEditing" type="text" v-model="paymentLinkUser.email"
              @input="handleFormInput('email', ...arguments)" required :errorMessage="getMessage('email')" />
            <span v-show="!isEditing" class="px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400"
              :class="{
        'bg-red-50 border-red-500': !formInputs.email.isValid
      }">
              {{ hideEmail(paymentLinkUser.email) }}
            </span>
            <span v-show="!isEditing" class="text-sm text-red-700">{{ getMessage('email') }}</span>
          </div>

          <div class="flex flex-col">
            <label class="form-label">CEP</label>
            <Input v-show="isEditing" type="text" v-model="paymentLinkUser.zipCode"
              @input="handleFormInput('zipCode', ...arguments)" v-mask="'#####-###'" required
              :errorMessage="getMessage('zipCode')" />
            <span v-show="!isEditing" class="px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-400"
              :class="{
        'bg-red-50 border-red-500': !formInputs.zipCode.isValid
      }">
              {{ hideCEP(paymentLinkUser.zipCode) }}
            </span>
            <span v-show="!isEditing" class="text-sm text-red-700">
              {{ getMessage('zipCode') }}
            </span>
          </div>
          <Button v-if="!isEditing && !isEdited" @click="editUserData()" class="max-w-max">
            <PencilAltIcon class="h-6 w-6" />
            Editar dados
          </Button>
        </form>
      </div>
    </div>
    <nav class="py-4 px-6 border-t bg-white lg:sticky lg:bottom-0 lg:z-10">
      <div class="flex gap-4">
        <div class="flex flex-grow gap-4 justify-end">
          <Button v-if="isEditing" @click="
        editUserData();
      isEdited = false;
      " class="w-full lg:w-auto">
            Cancelar
          </Button>
          <Button link kind="primary" @click="
        isEditing ? updateUser() : applyPreApprovedCreditForPaymentLink()
        " class="w-full lg:w-auto">
            {{
        isEditing
          ? "Salvar informações"
          : isExpired || isEdited
            ? "Analisar crédito"
            : "Avançar"
            }}
          </Button>
        </div>
      </div>
    </nav>
    <CancelCreatePaymentLinkModal :showModal="openModal" @onCloseModal="showModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { XIcon, PencilAltIcon } from '@vue-hero-icons/outline';
import stringMixin from '../../../mixins/string';
import utilMixin from '../../../mixins/util';
import CancelCreatePaymentLinkModal from '../components/CancelCreatePaymentLinkModal.vue';
import { Button, Input } from '../../../components';

export default {
  name: 'credit-verified',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  props: {
    isExpired: Boolean,
  },
  components: {
    XIcon,
    PencilAltIcon,
    CancelCreatePaymentLinkModal,
    Button,
    Input,
  },
  directives: {
    mask,
  },
  mixins: [stringMixin, utilMixin],
  data: () => ({
    openModal: false,
    originalData: {},
    openDropdown: false,
    isEditing: false,
    isEdited: false,
    formInputs: {
      phone: {
        isValid: false,
        validateFunction: 'isValidPhone',
        errorMessage: 'Insira um celular válido',
        currentMessage: '',
      },
      cpf: {
        isValid: false,
        validateFunction: 'isValidCpf',
        errorMessage: 'CPF inválido',
        currentMessage: '',
      },
      email: {
        isValid: false,
        validateFunction: 'isValidEmail',
        errorMessage: 'Email inválido',
        currentMessage: '',
      },
      zipCode: {
        validateFunction: 'isValidZipCode',
        isValid: false,
        errorMessage: 'CEP inválido',
        currentMessage: '',
      },
    },
  }),
  computed: {
    ...mapState('paymentLink', [
      'paymentLinkUser',
      'verifyPreApprovedCreditResult',
    ]),
    ...mapGetters('paymentLink', ['getExpirationDate', 'getPreApprovedCredit']),
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    maxValueWithoutDownPayment() {
      if (!this.getPreApprovedCredit) return null;

      let value = 0;
      if (this.getPreApprovedCredit.pricings) {
        const index = Object.values(
          this.getPreApprovedCredit.pricings,
        ).findLastIndex((item) => item[0].downPaymentPercentage === 0);

        if (index !== -1) {
          const lastRange = Object.keys(this.getPreApprovedCredit.pricings)[
            index
          ];
          value = Number(lastRange.match(new RegExp('(?<=[-]).*')));
        }
      }
      return value;
    },
    maxValueWithDownPayment() {
      let value = 0;
      if (this.getPreApprovedCredit && this.getPreApprovedCredit?.maxValue) {
        value = parseInt(this.getPreApprovedCredit?.maxValue, 10);
      }
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    clientName() {
      if (
        this.getPreApprovedCredit
        && this.getPreApprovedCredit?.checkoutData
      ) {
        const fullName = this.getPreApprovedCredit.checkoutData.name.toLowerCase().split(' ');
        const firstName = fullName[0];
        const lastName = fullName[fullName.length - 1];
        return `${firstName} ${lastName}`;
      }
      return '';
    },
  },
  mounted() {
    this.originalData = { ...this.paymentLinkUser };
    this.handleFormInput('cpf', this.paymentLinkUser.cpf);
    this.handleFormInput('email', this.paymentLinkUser.email);
    this.handleFormInput('zipCode', this.paymentLinkUser.zipCode);
    this.handleFormInput('phone', this.paymentLinkUser.phone);
  },
  methods: {
    ...mapActions('paymentLink', [
      'setPaymentLinkUserData',
      'createPreApprovedPaymentLink',
      'updatePaymentUser',
    ]),
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('toast', ['showToast']),
    showModal() {
      this.openModal = !this.openModal;
    },
    showDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    showDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    async goToPaymentLinkMenu() {
      await this.$router.replace({ name: 'payment-link-list' });
    },
    async updateUser() {
      this.handleFormInput('cpf', this.paymentLinkUser.cpf);
      this.handleFormInput('email', this.paymentLinkUser.email);
      this.handleFormInput('zipCode', this.paymentLinkUser.zipCode);
      this.handleFormInput('phone', this.paymentLinkUser.phone);
      if (
        !this.paymentLinkUser.email
        || !this.paymentLinkUser.phone
        || !this.paymentLinkUser.zipCode
        || !this.isValidForm
      ) {
        this.handleErrorMessages();
        return;
      }

      this.isEditing = false;

      this.setIsLoading({ isLoading: true, loadingMessage: 'Salvando dados' });

      try {
        await this.updatePaymentUser();
        this.isEdited = true;
      } catch (error) {
        this.showToast({
          toastMessage:
            'Erro ao salvar dados, contate o suporte com a mensagem.',
          toastType: 'danger',
          hideToastDelay: 5000,
        });
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    async applyPreApprovedCreditForPaymentLink() {
      this.handleFormInput('cpf', this.paymentLinkUser.cpf);
      this.handleFormInput('email', this.paymentLinkUser.email);
      this.handleFormInput('phone', this.paymentLinkUser.phone);
      this.handleFormInput('zipCode', this.paymentLinkUser.zipCode);
      if (!this.isValidForm && this.isEdited) {
        this.handleErrorMessages();
        return;
      }

      this.setIsLoading({
        isLoading: true,
        loadingMessage: 'Verificando crédito',
      });

      try {
        const { inputError, pendingPayment, message } = await this.createPreApprovedPaymentLink();

        if (pendingPayment) {
          await this.$router.replace({
            name: 'link-already-associated-to-cpf',
          });
          this.setIsLoading({ isLoading: false });
          return;
        }

        if (inputError?.maxDailyTries) {
          this.setIsLoading({ isLoading: false });
          await this.$router.replace({
            name: 'payment-link-number-of-link-exceeded',
          });
          return;
        }

        if (inputError) {
          this.formInputs[inputError].isValid = false;
          this.handleErrorMessages();
          this.setIsLoading({ isLoading: false });
          return;
        }

        if (
          ['PRE_APPROVED', 'APPROVED'].includes(
            this.verifyPreApprovedCreditResult,
          )
        ) {
          this.setIsLoading({
            isLoading: false,
            showIcon: 'success',
            showIconDelay: 1500,
          });
          await this.$router.replace({
            name: 'create-payment-link',
          });
        }

        if (['DENIED'].includes(this.verifyPreApprovedCreditResult)) {
          this.setIsLoading({ isLoading: false });
          await this.$router.replace({
            name: 'denied-payment-link',
          });
        }

        if (!['DENIED', 'PRE_APPROVED', 'APPROVED'].includes(this.verifyPreApprovedCreditResult)) {
          const errorMessage = message
            ? `${message}. Contate o suporte.`
            : 'Contate o suporte.';
          this.showToast({
            toastMessage: `Erro inesperado: ${errorMessage}`,
            toastType: 'danger',
            hideToastDelay: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || error.response.data;
          if (
            errorMessage
            === 'Daily limit exceed, contact comercial@parcelex.com.br to know about your limits'
          ) {
            this.showToast({
              toastMessage:
                'Limite de aplicações diárias atingido, contate o suporte com a mensagem.',
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          } else {
            this.showToast({
              toastMessage: `Erro inesperado: ${errorMessage}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          }
        }
      }

      this.setIsLoading({ isLoading: false });
    },
    async jumpStep() {
      await this.$router.replace({ name: 'create-payment-link' });
    },
    handleFormInput(field, value) {
      this.setPaymentLinkUserData({ field, value });
      const { validateFunction } = this.formInputs[field];
      this.formInputs[field].isValid = this[validateFunction](value);
      if (this.formInputs[field].currentMessage !== '') {
        this.formInputs[field].currentMessage = '';
      }
    },
    handleErrorMessages() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(field) {
      return this.formInputs[field].currentMessage;
    },
    editUserData() {
      if (this.isEditing && !this.isEdited) {
        this.setPaymentLinkUserData({
          field: 'cpf',
          value: this.originalData.cpf,
        });
        this.setPaymentLinkUserData({
          field: 'email',
          value: this.originalData.email,
        });
        this.setPaymentLinkUserData({
          field: 'zipCode',
          value: this.originalData.zipCode,
        });
        this.setPaymentLinkUserData({
          field: 'phone',
          value: this.originalData.phone,
        });
      } else {
        this.handleFormInput('email', '');
        this.handleFormInput('zipCode', '');
        this.handleFormInput('phone', '');
      }
      this.isEditing = !this.isEditing;
    },
  },
};
</script>

<style scoped>
.sub-message {
  font-size: 14px;
  line-height: 20px;
}
</style>
